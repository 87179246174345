import { Meta } from 'zhead';
import InvoiceCollection from '../collections/InvoiceCollection';
import Invoice from '../models/Invoice';
import InvoiceSchema from '../schemas/InvoiceSchema';
import { InvoiceFormResponse, InvoiceNotifyFormResponse, InvoiceValidateCustomInternalNumberResponse } from './types';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { JsonRequestPayload, ModelEndpointResponse } from '~~/app/base/endpoints/types';
import { Attributes, BaseSchema, ModelType, Relationships } from '~~/app/base/schemas/BaseSchema';
import Pdf from '~/app/pdf/models/Pdf';

export default class InvoiceEndpoint extends BaseEndpoint<Invoice, InvoiceCollection> {
    model: ModelType = ModelType.INVOICES;
    path = 'tenant/invoicing/invoices';
    include = 'contact,contact.defaultVatRate,lead';

    async autosave(schema: InvoiceSchema): Promise<ModelEndpointResponse<Invoice>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `-actions/autosave?${this.getSearchParamsForSingleModel()}`,
            data: schema.json(),
        };

        return await this.request<ModelEndpointResponse<Invoice>>(payload);
    }

    async form(documentType: string, uuid?: string): Promise<InvoiceFormResponse> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    type: 'invoice-form',
                    attributes: {
                        documentType,
                    },
                },
            },
            path: '-actions/form',
        };
        if (uuid) {
            payload.data.data!.id = uuid;
        }

        const response = await this.requestRaw(payload);
        const { data } = await response.json();

        const formResponse: InvoiceFormResponse = {
            conditions: data.attributes.conditions,
            dueAt: moment(data.attributes.dueAt),
            earliestCustomDateAvailable: moment(data.attributes.earliestCustomDateAvailable),
            nextNumber: data.attributes.nextNumber,
        };
        return formResponse;
    }

    async previewHtml<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema | null, uuid?: string): Promise<InvoicePreviewHtmlResponse> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: schema || {},
            },
            path: '-actions/preview-html',
        };
        if (uuid) {
            payload.data!.data!.id = uuid;
        }

        const response = await this.requestRaw(payload);
        const { data } = await response.json();

        const previewHtmlResponse: InvoicePreviewHtmlResponse = {
            html: data.attributes.html,
        };
        return previewHtmlResponse;
    }

    async preview<Schema extends BaseSchema<Attributes, Meta, Relationships>>(schema: Schema): Promise<Model> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: schema,
            },
            path: '-actions/preview',
        };

        const pdf = await this.setModel(ModelType.PDFS).request<ModelEndpointResponse<Pdf>>(payload);
        this.setModel(ModelType.INVOICES);
        return pdf;
    }

    async pdf(uuid: string): Promise<ModelEndpointResponse<Pdf>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `${uuid}/-actions/pdf`,
        };

        const pdf = await this.setModel(ModelType.PDFS).request<ModelEndpointResponse<Pdf>>(payload);
        this.setModel(ModelType.INVOICES);
        return pdf;
    }

    async pricelessPdf(uuid: string): Promise<ModelEndpointResponse<Pdf>> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            path: `${uuid}/-actions/priceless-pdf`,
        };

        const pdf = await this.setModel(ModelType.PDFS).request<ModelEndpointResponse<Pdf>>(payload);
        this.setModel(ModelType.INVOICES);
        return pdf;
    }

    async markAsSent(uuid: string, customInternalNumber: number): Promise<ModelEndpointResponse<Invoice>> {
        return await this.customAction(uuid, 'sent', 'PATCH', {
            data: {
                type: 'invoices',
                id: uuid,
                attributes: {
                    customInternalNumber,
                },
            },
        });
    }

    async markAs(uuid: string, status: string): Promise<ModelEndpointResponse<Invoice>> {
        return await this.customAction(uuid, status);
    }

    async notifyForm(uuid: string, driver: 'email' | 'sms' | 'letter'): Promise<InvoiceNotifyFormResponse> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    type: 'invoice-notify-form',
                    attributes: {
                        driver,
                    },
                },
            },
            path: `${uuid}/-actions/notify/form`,
        };

        const response = await this.requestRaw(payload);
        const { data } = await response.json();

        const formResponse: InvoiceNotifyFormResponse = {
            title: data.attributes.title,
            subject: data.attributes.subject,
            text: data.attributes.text,
            copy: data.attributes.copy,
            credits: data.attributes.credits,
        };
        return formResponse;
    }

    async notify(uuid: string, driver: 'email' | 'sms' | 'letter', data: any): Promise<ModelEndpointResponse<Invoice>> {
        return await this.customAction(uuid, `notify/${driver}`, 'POST', {
            data: {
                type: 'invoices',
                id: uuid,
                attributes: data,
            },
        });
    }

    async validateCustomInternalNumber(uuid: string, customInternalNumber: number) {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    type: 'invoice-custom-internal-number',
                    attributes: {
                        customInternalNumber,
                    },
                },
            },
            path: `${uuid}/-actions/validate/custom-internal-number`,
        };

        const response = await this.requestRaw(payload);
        const { data } = await response.json();

        const validationResponse: InvoiceValidateCustomInternalNumberResponse = {
            isUnique: data.attributes.isUnique,
            isSequential: data.attributes.isSequential,
            isPerfectlySequential: data.attributes.isPerfectlySequential,
            missingInternalNumbers: data.attributes.missingInternalNumbers,
            missingFormattedNumbers: data.attributes.missingFormattedNumbers,
        };
        return validationResponse;
    }

    async storeDepositInvoice(uuid: string, percentage: number): Promise<ModelEndpointResponse<Invoice>> {
        return await this.customAction(uuid, 'deposit-invoice', 'POST', {
            data: {
                type: 'invoices',
                id: uuid,
                attributes: {
                    percentage,
                },
            },
        });
    }

    async storeFinalInvoice(uuid: string, depositInvoices: string[]): Promise<ModelEndpointResponse<Invoice>> {
        return await this.replicate(uuid, {
            documentType: 'invoice',
            depositInvoices,
        });
    }

    async uploadFrenchVatCertificate(uuid: string, file: File): Promise<ModelEndpointResponse<Invoice>> {
        const data = new FormData();
        data.append('data[type]', 'invoice-french-vat-certificate');
        data.append('data[id]', uuid);
        data.append('data[attributes][frenchVatCertificate]', file);

        return await this.customAction(uuid, 'french-vat-certificate', 'POST', data);
    }
}
